.yellow_btn {
  color: #000 !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
  padding: 10px 30px !important;
}

.red_btn {
  color: #fff !important;
  background-color: #f7132e !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
  padding: 10px 20px !important;
}

/* .upBtn {
  color: #fff !important;
  background-color: #f7132e !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
  display: block !important;
  position: absolute !important;
  top: 75px;
  right: 0;
} */

.outlined_btn:hover {
  background-color: #fff !important;
  color: rgba(54, 54, 54, 0.5) !important;
  border-color: #363636 !important;
}

.outlined_btn {
  font-family: "Montserrat" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: rgba(54, 54, 54, 0.5) !important;
  padding: 9px 20px !important;
  background-color: #fff !important;
  border: 1px solid #363636 !important;
  margin-right: 20px;
}

.video_btn {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: rgba(54, 54, 54, 0.5) !important;
  padding: 9px 20px !important;
  background-color: #ececec !important;
  border: none !important;
  margin-right: 20px;
}
