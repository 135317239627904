.landing_bottom_heading {
  font-size: 36px;
  font-weight: 600;
  font-family: "Montserrat" !important;
  line-height: 64px;
  color: #2c3847;
}

.landing_page_hr {
  margin: 0;
  padding: 0;
  color: #e2e2e2;
}

.four_img {
  text-align: center;
  margin-bottom: 40px;
}

.landing_page_img {
  width: 90px;
}

.landing_create_account_div {
  margin-top: 187px;
  padding: 0px 110px;
}

.landingpage_create_account_para {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter" !important;
  line-height: 24px;
  color: #363636;
}

.landing_create_account {
  font-size: 24px;
  font-weight: 500;
  font-family: "Montserrat" !important;
  line-height: 30px;
  color: #222831;
}

.work_left_div {
  margin-top: 102px;
  text-align: center;
}

.middle_sub_heading {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter" !important;
  color: #363636;
  line-height: 24px;
  text-align: center;
  margin: 0;
}

.middle_heading {
  font-size: 40px;
  font-weight: 500;
  font-family: "Montserrat" !important;
  color: #313131;
  line-height: 48px;
  text-align: center;
  margin-top: 40px;
}

.banner_div {
  padding-bottom: 50px;
  background-color: #d9d9d9;
}
.banner_div h2 span {
  color: #000000;
}
.banner_div h2 {
  font-family: "Montserrat" !important;
  color: #363636;
  font-weight: 600;
  font-size: 79px;
  line-height: 96px;
}

.banner_div p {
  font-family: "Mulish" !important;
  color: #363636;
  font-weight: 400;
  font-size: 21px;
  line-height: 40px;
}

.icon_banner_card img {
  width: 100%;
}
.icon_banner_card img {
  width: 100%;
  height: 295px;
}
.first_card {
  margin-top: 40px !important;
}

.fourth_card {
  margin-top: -40px !important;
}

.banner_div .social_icons_div h3 {
  font-family: "Montserrat" !important;
  font-size: 42px;
  font-weight: 500;
  line-height: 52px;
  color: #363636;
  margin-bottom: 40px;
  margin-top: 45px;
}

.social_icons_div ul {
  width: 100%;
  padding: 0;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.social_icon_list li {
  margin-right: 20px;
}

.social_icon_list li:nth-child(6) {
  margin-right: 30px;
}

.social_icon_list li img {
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  .landing_create_account_div {
    padding: 0px 15px;
    margin-top: 15px;
    text-align: center;
  }
  .social_icons_div ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .four_img {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 15px;
  }
  .listblock {
    width: auto !important;
  }
  .fourth_card {
    margin-top: 0px !important;
  }
  .work_left_div {
    margin-top: 24px;
    margin-bottom: 12px;
  }
  .banner_div h2 {
    font-size: 36px;
    line-height: 50px;
    text-align: center;
  }
  .banner_div p {
    text-align: center;
  }
  .landing_bottom_heading {
    font-size: 30px;

    line-height: 40px;
  }
}
