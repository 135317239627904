.contain {
  width: 100%;
  padding: 10px 3px 50px;
}

.contain_color {
  background-color: #f9f9f9;
  margin-left: 256px;
  min-height: calc(100vh - 90px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.contain_color.nopadding {
  margin-left: 256px;
}

@media only screen and (max-width: 992px) {
  .contain_color {
    margin-left: 0px;
  }
  .contain {
    padding: 40px 10px 50px;
  }
}
