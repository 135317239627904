.input:focus {
  box-shadow: none !important;
  border-color: #cccccc !important;
}

.text_area:focus {
  box-shadow: none !important;
  border-color: #cccccc !important;
}

.form_check {
  width: 100%;
  display: flex !important;
  padding-left: 1px;
  align-items: center !important;
}

.tag_input {
  height: 33px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 0px;
  width: 100% !important;
}

.input_search {
  position: relative;
  width: 60%;
}

.search_input:focus {
  box-shadow: none !important;
  border-color: #cccccc !important;
}

.search_input {
  margin-left: 6.5rem;
  width: 100% !;
  padding-left: 54px !important;
}

.password_input:focus {
  border-color: #ccc !important;
  box-shadow: none !important;
}

.password_input {
  position: relative;
}

.eye {
  cursor: pointer;
  position: absolute;
  right: 2.5rem;
  margin-top: -38px;
}

.attach_icon {
  position: absolute;
  top: 10px;
  left: 8rem;
}

.red_button {
  font-family: "Montserrat" !important;

  font-size: 16px !important;
  font-weight: 700 !important;

}

@media (max-width: 992px) {
  .search_input {
    margin-left: 0rem;
  }
  .red_button {
    position: relative;
    right: 0;

    bottom: 0px;
    margin-top: 5px;
    width: 100%;
  }
}
