/*  */

.sidebar_submenu_name_div {
  margin-top: 16px;
  margin-bottom: 16px;
}

.sidebar_name_div {
  margin-top: 26px;
}

.new_video_count {
  padding: 2px 6px;
  background-color: #f7132e;
  border-radius: 100%;
  color: #fff;
  margin-left: 8px;
}

.submenu_name:hover {
  color: #363636 !important;
}

.submenu_name {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter" !important;
  font-style: normal;
  line-height: 15px;
  color: #363636;
  margin-left: 52px;
  text-decoration: none;
}

.name:hover {
  color: #f7132e;
}

.name {
  display: flex;
  text-decoration: none;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter" !important;
  line-height: 20px;
  color: #363636;
  margin-top: 10px;
}

.active {
  color: #f7132e;
}

.sidebar {
  width: 256px;
  position: fixed;
  background-color: #ffffff;
}

.viewMore {
  font-size: 12px;
  font-weight: bold;
  font-family: Franklin !important;
  font-style: normal;
  line-height: 15px;
  color: #363636;
  margin-left: 52px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  bottom: 15vh;
  margin-top: 10vh !important;
}

.showLess {
  font-size: 12px;
  font-weight: bold;
  font-family: Franklin !important;
  font-style: normal;
  line-height: 15px;
  color: #363636;
  margin-left: 52px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  bottom: 15vh;
}
.sidebar_icon svg {
  width: 20px;
  height: 20px;
}
.small_items_div {
  height: 300px;
  overflow: auto;
}
.small_items_div::-webkit-scrollbar {
  width: 1px;
}

@media only screen and (max-width: 992px) {
  .sidebar {
    display: none;
  }
  .mobile {
    display: block;
  }
}
