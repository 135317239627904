.logo {
  font-size: 26px !important;
  font-weight: 400;
  line-height: 30px;
  color: #363636 !important;
  margin-left: 20px;
  text-decoration: none;
}

.text_sub_heading {
  font-family: Inter !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  margin-top: 24px;
}

.facebook {
  width: 35px;
  height: 35px;
}

.whatsapp {
  width: 35px;
  height: 35px;
}

.twitter_text {
  margin-right: 10px;
}

.more_text {
  margin-right: 12px;
}

.middle_text_div {
  display: flex;
  margin-top: 14px;
  align-items: center;
  justify-content: space-between;
}

.middle_img_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 20px 0px 20px;
}

.drop_heading {
  font-size: 14px;
  font-weight: 500;
  font-family: Inter !important;
  line-height: 20px;
  color: #000000;
}

.header_dropdown {
  position: absolute !important;
  inset: 63px 20px auto auto !important;
  width: 350px !important;
}

.video_img {
  width: 754px;
  height: 366px;
}

.text {
  font-size: 18px;
  font-weight: 400;
  font-family: Inter !important;
  line-height: 20px;
  color: #000000;
  margin: 25px 6rem;
}

.sub_heading {
  font-size: 18px;
  font-weight: 400;
  font-family: Inter !important;
  line-height: 20px;
  color: #6f767e;
}

.sub_heading_div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  padding: 0px 8px;
}

.heading {
  font-family: "Inter" !important;
  font-size: 30px;
  font-weight: 500;
  line-height: 32px;
  color: #000000;
  margin-bottom: 0;
  margin-left: 20px;
}

.video_avatar {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.heading_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
}

.video_video {
  padding-top: 25px;
  width: 100%;
  position: fixed;
}

.delete_icon {
  margin-left: 6px;
  color: #6f767e;
}

.delete_icon:hover {
  color: #e7132f !important;
}

.share_icon:active {
  color: #e7132f !important;
}

.share_icon:hover {
  color: #e7132f !important;
}

.share_icon {
  margin-left: 20px;
  color: #6f767e;
}

.icon:hover {
  color: #e7132f !important;
}

.icon {
  margin-left: 35px;
  color: #6f767e;
}

.videoheader_nav {
  display: flex;
  align-items: center;
  width: 100%;
  color: #6f767e;
}

.video_header {
  height: 60px;
  position: fixed !important;
  z-index: 999;
  background-color: #fff;
  width: 100%;
  border-bottom: 2px solid #ccc;
}
.downloading_bar {
  width: 900px;
  text-align: right;
}

.downloading_bar h5 {
  font-size: 15px;
  color: red;
  font-weight: 500;
}

.react_player_div {
  padding-top: 0px;
  width: 100% !important;

  overflow: hidden;
}
.react_player_range {
  margin: 0 auto;
  /* width: 485px; */
  display: flex;
  justify-content: space-between;
}

.timer_video {
  margin-left: 30px;
  /* width: 300px; */
}
.timer_video p span {
  font-size: 12px;
}

.timer_video p {
  margin: 0;
}

.volume_section_reactplayer {
  display: flex;
  align-items: center;
  /* width: 100%; */
  margin-left: 25px;
}

.volume_section_reactplayer img {
  width: 15px;
  height: 15px;
}

.volume_section_reactplayer input {
  width: 100px;
  margin: 0 7px;
}

.playing_btn_player img {
  width: 27px;
  height: 27px;
}

.playing_btn_player img:nth-child(2) {
  margin: 0 15px;
}
.playing_btn_player svg {
  width: 40px;
  height: 40px;
  color: #fff;
  /* font-size: 20px; */
}

.video_control_div {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.video_input_slider::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: red;
}

.volume_section_reactplayer svg {
  width: 24px;
  height: 24px;
  color: #fff;
}

.video_input_slider::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: red;
}

.volume_section_reactplayer svg {
  width: 24px;
  height: 24px;
  color: #fff;
}
.playing_btn_player svg {
  width: 40px;
  height: 40px;
  color: #fff;
  /* font-size: 20px; */
}

.video_control_div {
  display: flex;
  align-items: center;
}

.video_input_slider::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: red;
}

.volume_section_reactplayer svg {
  width: 24px;
  height: 24px;
  color: #fff;
}

.volume_section_reactplayer svg {
  width: 24px;
  height: 24px;
  color: #fff;
}
.cstm_player_volume {
  display: flex;
  width: 130px;
  align-items: center;
  margin: 0 25px 0 10px;
}
.custom_play_button svg {
  width: 40px;
  height: 40px;
}

.cstm_player_volume input {
  width: 100px;
  margin: 5px 5px 0;
}
.timer_video_cstmplayer {
  margin: 0;
  font-size: 12px;
}
.timer_video_cstmplayer p {
  margin: 0;
  color: #fff;
}
.cstm_player_volume svg {
  height: 22px;
  width: 22px;
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {
  .video_react_player_div {
    padding-top: 20px;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .video_video {
    /*    padding-top: 65px;*/
    overflow-x: hidden;
    overflow-y: hidden;
    /* position: relative; */
    width: 100%;
  }

  .video_header {
    height: 65px;
  }

  .video_img {
    width: 638px;
    height: 300px;
  }

  .heading {
    font-size: 25px;
  }

  .text {
    margin: 25px 0rem;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .text {
    margin: 25px 6px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .text {
    margin: 25px 0px;
  }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
  .text {
    margin: 25px 6px;
  }
}

@media screen and (max-width: 480px) and (min-width: 320px) {
  .text {
    margin: 25px 6px;
  }
}
