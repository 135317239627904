.add_video_heading {
  font-family: "Inter" !important;
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  text-align: center !important;
}

.edit_input {
  width: 100%;
  padding: 8px 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  margin-top: 1px;
}

.paste_icon {
  font-size: 24px;
  color: #f7132e;
}

.tag_input {
  width: 100% !important;
}
.edit_icon_span {
  right: 25px;
  cursor: pointer;
}
.edit_icon_span h5{
  color: red;
}
.edit_hover {
  position: relative;
}

.edit_icon {
  font-size: 24px;
  margin-top: 10px;
  width: 40px;
    height: 40px;
}
.edit_icon_span svg{
  color: red;
}

.modal_img {
  position: relative;
  width: 100% !important;
  height: 217px;
  border-radius: 12px;
  margin-bottom: 16px;
  object-fit: cover;
}
.edit_thumbnail_div {
  display: none;
  top: 0;
  width: 100%;
  height: 217px;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.74);
}

.remove_thmbunail {

  color: #fff;
  font-size: 26px;
  margin-top: -6px;
  cursor: pointer;
}

.edit_thumbnail_div_remove {
  background-color: red;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 5px;
}

.edit_hover:hover .edit_thumbnail_div {
  display: flex;
}
  
.search_input_search {
  padding-left: 4px !important;
  width: 100% !important;
  margin-bottom: 20px;
}

.search_input_search:focus {
  box-shadow: none !important;
  border-color: #cccccc !important;
}

.search_input:focus {
  box-shadow: none !important;
  border-color: #cccccc !important;
}

.attach_icon {
  position: absolute;
  top: 10px;
  left: 15px !important;
}

.actualThumbnail {
  display: none;
/*  position: absolute;*/
  right: 25px;
  cursor: pointer;
  display: inline-flex;
  float: right;
}
.save_video_scroll {
  height: 450px;
  overflow: auto;
  padding-right: 5px;
}

.save_video_scroll::-webkit-scrollbar {
  width: 3px;
  opacity: 0.3;
}

.save_video_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.save_video_scroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.save_video_scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (min-width: 1920px) and (max-width: 2555px) {
  .save_video_scroll {
    height: auto;
    overflow: auto;
    padding-right: 5px;
  }
}