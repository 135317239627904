/* header */

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
}

.header_item_feedback {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header_attachicon {
  position: absolute;
  top: 10px;
  left: 7.5rem;
}
.header_attachicon svg {
  width: 20px;
  height: 20px;
}

.search {
  width: 53%;
}

.header_right_side {
  margin-left: 36px;
}
.header-right-section svg {
  width: 20px;
  height: 20px;
}

.header_dropdown.a.dropdown-item:active {
  background-color: #ccc !important;
}

.header_dropdown.a.dropdown-item {
  color: #6f767e !important;
}

.header_bell_dropdown {
  position: absolute !important;
  inset: 63px 25px auto auto !important;
  width: 400px;
  height: 250px;
}

.header_dropdown {
  position: absolute !important;
  inset: 110px 25px auto auto !important;
  width: 300px !important;
  padding: 0 !important;
}

.avatar {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.header_nav {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "Montserrat" !important;
}

.header_header {
  height: 90px;
  position: fixed !important;
  z-index: 999;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #363636;
}

.header {
  height: 90px;
  position: fixed !important;
  z-index: 999;
  background-color: #fff;
  width: 100%;
}

.menu:hover {
  color: #000000;
  text-decoration: none;
  border-bottom: 2px solid #fbe609 !important;
}

.active {
  font-weight: 600 !important;
  color: #000000;
  text-decoration: none;
  border-bottom: 2px solid #fbe609 !important;
  font-family: "Montserrat" !important;
}

.menu {
  color: #363636;
  text-decoration: none;
  font-weight: 400 !important;
  margin: 0 20px;
}

.logo {
  font-family: "Inter" !important;
  font-size: 26px !important;
  font-weight: 400;
  line-height: 31.47px;
  color: #363636 !important;
}

.header_logo {
  font-size: 26px !important;
  font-weight: 400;
  line-height: 30px;
  color: #363636 !important;
  margin-left: 20px;
}

.crossIcon {
  font-size: 26px !important;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
}

.globalSearchIcon {
  position: absolute;
  top: 10px;
  left: 7.5rem;
  color: red;
}
.profil_name_div {
  margin-top: 30px;
}

.profil_name_div p {
  margin: 0;
  font-size: 12px;
  color: #000;
}

/*===============-=-=-=-=-=-=- new css ns 3/2/2023 =-=-===-=-=-=-============ */

.loginBtn {
  margin-left: 20px;
}

.mainHeader {
  margin-left: auto;
}
.dropmenu {
  margin-left: 50px;
}
/* add a new header responsive css */

@media (max-width: 767px) {
  .loginBtn {
    padding: 7px 12px !important;
    margin-left: 0px !important;
    margin-top: 5px;
  }

  .mainHeader {
    margin-right: auto;
    width: 100%;
  }
  .menu {
    display: block;
  }
  .fixedmenu {
    position: absolute;
    top: 100%;
    left: 0px;
    background: #f7f7f7;
    padding: 10px;
    right: 0px;
  }
  .search {
    width: 100%;
  }
  .header_attachicon {
    left: 14px;
  }
  .header_right_side {
    margin-left: 0px;
  }
  .dropmenu {
    margin-left: 15px;
  }
  .avatar {
    width: 30px;
    height: 30px;
  }
  .profil_name_div {
    margin-top: 0px;
    position: relative;
    top: 10px;
  }
}

@media (max-width: 992px) {
  .header_menu__ONIER {
    margin: 0 10px;
  }

  .menu {
    margin: 0 10px;
    margin-right: auto;
    padding: 5px 0px;
  }

  .loginBtn {
    padding: 5px 10px !important;
    margin-left: 10px !important;
  }

  .logo {
    font-size: 20px !important;
    margin-left: 10px;
  }

  .loginBtn {
    margin-top: 15px;
  }
}
