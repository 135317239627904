.footer_copyright_onestream {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-weight: 400;
  color: #2b2b2b;
  line-height: 20px;
  text-align: end;
  padding: 30px 0px;
  margin-bottom: 0;
}

.footer_ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin-bottom: 0;
}

.footer_li {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #6b6b6b;
  margin-right: 52px;
  padding: 30px 0px;
  font-family: "Montserrat" !important;
}
.footer_li a {
  text-decoration: none;
  color: #6b6b6b;
}

@media only screen and (max-width: 992px) {
  .footer_copyright_onestream {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
  }

  .footer_li {
    margin-right: 0px;
    padding: 5px;
    font-size: 14px;
  }
  .footer_li a {
    text-decoration: none;
    color: #6b6b6b;
  }
  .footer_ul {
    justify-content: center;
  }
}
