/*  */

.login_forgot {
  font-family: "Montserrat" !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #0057ff !important;
}

.login_remember_me {
  display: flex !important;
  align-items: center !important;
}

.login_remember_me label {
  margin-left: 8px;
  margin-top: 4px;
}

.forget_title {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #828282;
  margin-bottom: 24px;
}

.resend {
  color: #e7132f;
  cursor: pointer;
}

.verification_text {
  font-family: "Open Sans" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
}

.otp_time {
  font-family: "Open Sans" !important;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #e7132f;
  text-align: center;
  margin-top: 25px;
}

.verification_title {
  font-family: "Open Sans" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  margin-bottom: 25px;
}

.inputStyle input {
  width: 4rem !important;
  height: 4rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.signup_form_control:focus {
  border-color: #ccc !important;
  box-shadow: none !important;
}

.eye {
  cursor: pointer;
  position: absolute;
  right: 2.5rem;
  margin-top: -32px;
}

.sub_heading {
  font-size: 17px;
  font-weight: 700;
  font-family: "Montserrat" !important;
  line-height: 20px;
  color: #e7132f;
  text-align: center;
  text-transform: uppercase;
}

.title_account {
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #222222;
  text-align: center;
  margin-bottom: 10px;
}

.heading {
  font-family: "Montserrat" !important;
  font-size: 34px;
  font-weight: 700;
  color: #222222;
  line-height: 40px;
  text-align: center;
  margin-bottom: 5px;
}
