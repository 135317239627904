@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

body {
  margin: 0;
  padding: 0px !important;
  overflow-y: auto !important;
  width: 100% !important;
}

.sidebar_contain {
  display: flex;
  padding-top: 90px;
}

.modal-content {
  margin-top: 0px;
}

.modal-header {
  border-bottom: none !important;
}

.container {
  --bs-gutter-x: 0rem !important;
}

.danger-bg {
  background-color: #e7132f !important;
}

.warning-bg {
  background-color: #fbe609 !important;
}

.bg-white {
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

.red-btn {
  border-radius: 5px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
}

label {
  font-weight: 500;
  color: #222222 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px !important;
}

input#bootstrap-input {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #999999 !important;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif !important;
}

.link-btn,
.link-btn:hover {
  color: #0057ff;
  text-decoration: none;
  cursor: pointer;
}

.danger-text,
.danger-text:hover {
  color: #e7132f;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 17px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

/* .slick-initialized .slick-slide {
  margin: 10px;
} */

.ant-upload ant-upload-select {
  display: none !important;
}

.copyClass {
  cursor: pointer;
}

.copyClass:hover {
  color: red;
}

.activeSubSideMenuClass {
  color: red;
}

.videoDownloadIcon {
  position: relative;
  left: 190vh;
  color: white;
  background-color: white;
  bottom: 30px;
  cursor: pointer;
}

.videoDownloadLoading {
  position: relative;
  left: 190vh;
  color: white;
  bottom: 30px;
  cursor: pointer;
}

.Header {
  display: none !important;
}

.height {
  height: "500px";
}

.instagramDownloadButton {
  cursor: pointer;
}

.example {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #f3f3f36e;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover-button-div {
  position: relative;
  text-align: center;
  /* width: 500px; */
  margin: 0 auto;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.VideoPlayComponentLoader {
  display: flex;
  justify-content: center;
  position: relative;
  /* top: 50vh; */
  align-items: center;
  height: 75vh;
}
.VideoPlayComponentLoader h5 {
  color: red;
  margin-top: 10px;
  font-size: 20px;
}
.ant-spin-dot-item {
  background-color: red !important;
  width: 15px;
  height: 15px;
}
ant-spin-dot {
  width: 100px;
  height: 100px;
}
.Nodatafound {
  position: relative;
  left: 50vh;
  top: 40vh;
  font-weight: 500;
}

.deleteVideoImg {
  display: inline;
  width: 100%;
  height: 80%;
}

.volume {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.modal-body {
  padding-top: 5px !important;
}

.modal-header {
  padding-bottom: 5px !important;
}

.ant-upload-list-item.ant-upload-list-item-undefined,
.ant-upload-list-item.ant-upload-list-item-error {
  border: 5px solid #a5a58d !important;
  padding: 0 !important;
  border-radius: 100% !important;
}

.ant-upload-list-item.ant-upload-list-item-undefined::before,
.ant-upload-list-item.ant-upload-list-item-error::before {
  width: calc(100% - 0px) !important;
  height: calc(100% - 0px) !important;
}

.ant-upload-list-item-container {
  width: 150px !important;
  height: 150px !important;
}

.cropper-profile {
  position: relative;
}

.cropper-profile::before {
  height: 400px;
  width: 2px;
  position: absolute;
  content: "";
  border-right: 2px solid #e6e6e6;
  top: 35px;
  right: 0;
}

.muteVolume {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.playerIcon {
  width: 5vh;
  height: 5vh;
}

.CircularProgressbar {
  width: 40px !important;
  margin: auto !important;
}

.ant-upload-list-item-thumbnail {
  border-radius: 100%;
}

.ant-upload-list-item::before {
  border-radius: 100%;
}

.react-tags__combobox {
  padding: 0 !important;
  margin: 0;
}

.react-tags {
  border: 1px solid #ccc !important;
}

.simplebar-content {
  height: 700px;
}

.card-hover-card-body.card-body {
  padding: 0 0px;
}

#temp {
  width: 100%;
  /*  margin-right: 20px;*/
}

.filterOption {
  background-color: #e7132f !important;
  width: 150px !important;
}

.ant-upload-list-item-actions {
  display: none !important;
}

.profileLoader {
  position: relative;
  left: 50vh;
  top: 40vh;
  background-color: #f3f3f36e;
  z-index: 9999;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;

  height: 3px;
  border-radius: 1px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0.25rem solid red;
  /* box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3); */
  border-radius: 50%;
  background: red;
  cursor: pointer;
  height: 15px;
  position: relative;
  bottom: 6.5px;
  width: 15px;
  transform: translateY(calc(-50% + 8px));
  z-index: 11111 !important;
}

.playlistLoader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #f3f3f36e;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
}

.hover-button-div.custom_player {
  /*  width: 260px;*/
  /*  margin: 0 auto;*/
  padding: 20px;
  /*  background-color: rgba(0, 0, 0, 0.1);*/
  margin-top: -87px !important;
}

video.video_player_custome {
  width: 100%;
  margin: 0px auto;
  background-image: url(../src/assets/image/blur-bg.jpeg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 99vh;
}

.hover-button-div.custom_player svg {
  color: #fff;
}

.filterOption {
  border: none !important;
}

.video_padding_body {
  padding: 10px 20px !important;
}

.ant-message {
  z-index: 999999 !important;
}
.ant-message .ant-message-notice-content {
  z-index: 999999 !important;
}

.header_progress_download {
  position: fixed;
  width: 100%;
  left: 0;
  top: 55px;
}
.header_progress_download .progress {
  height: 6px;
}

/* .custom_player_controls_div {
   margin-top: 10px;
} */

.react-multiple-carousel__arrow {
  z-index: 9 !important;
}

.hover-button-div {
  position: fixed;
  text-align: center;
  bottom: 18px;
  left: 0;
  right: 0;

}
.title_text_shorts {
  margin-top: 7px;
  height: 50px;
  text-align: left;
  color: #000;
  text-decoration: none;
  font-size: 14px;
}
a.dropdown-item {
  border-bottom: 1px solid #dddddd;
  padding: 3px 10px;
}
a.dropdown-item:focus,
a.dropdown-item.active,
a.dropdown-item:hover {
  color: #fff;
  background-color: #e7132f;
  border-radius: 5px;
}
.dropdown-menu.show {
  padding: 0;
}
/*-==-=-=--=-=-==-=--responsive media-=-=-=-=-=-=-=-*/
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .header_progress_download {
    top: 60px;
  }
}

.createPlaylist {
  margin-left: 20px;
  cursor: pointer;
}


.force-click-control {
  position: absolute;
  top: 0;
  width: 100%;
  height: 80%;
  cursor: pointer;
  z-index: 9999;

  /* background-color: rgba(0, 0, 0, 0.7); */
}
img {
  max-width: 100%;
}

.menuicon {
  align-items: center;
  padding: 4px 10px;
  border-radius: 0px 5px 5px 0px;
  display: none;

  position: fixed;
  margin-left: -12px;
  z-index: 100;
  margin-top: -40px;
}
@media (max-width: 992px) {
  .menuicon {
    display: flex;
  }
  .banner-left-text {
    text-align: center;
  }
  .navbar-nav .dropdown-menu {
    position: absolute !important;
  }
  .cropper-profile::before {
    display: none;
  }
}

#antd-msg-close {
  position: fixed;
  top: 10px;
  width: 100%;
  z-index: 9999999;
  height: 30px;
}
.anticon anticon-close-circle {
  cursor: pointer !important;
}

.mix-Cloud-force-hide {
  top: 0;
  z-index: 999;
  width: 100%;
  height: 90vh;
  position: fixed;
  background: black;
  display: flex;
  align-items: center;
}

.mix-Cloud-force-hide img {
  margin: 0 auto;
  width: auto;
  height: 80vh;
}
.savebtn-new{
  position: absolute;
    right: -6.4rem;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    bottom: 2px;
}
