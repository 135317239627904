.create_user {
    font-size: 12px;
    font-weight: 500;
/*    line-height: 20px;*/
    color: #000000;
    margin-bottom: 0;
    margin-left: 2px;
}

.create_user_div {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 3px;
    padding: 3px 5px 3px 3px;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 8px;
    position: relative;
}

.create_user_div:hover .close_icn {
  opacity: 1;
}

.create_avatar_img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.sub_text {
    font-family: "Inter" !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #363636;
}

.create_input {
    background-color: #f3f5f6 !important;
}

.close_icn {
    color: red;
    opacity: 1;
    margin-bottom: 0;
    margin-top:-4px;
    margin-left: 2px;
}

.friend_list {
    top: -15px;
    padding: 5px 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 100%;
    position: absolute;
    border-radius: 5px;
    z-index: 9;
}

.friend_list small {
    cursor: pointer;
    width: 100%;
    padding: 5px;
    display: block;
}
.people_access_list{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}